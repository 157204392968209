import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Layout,
  Row,
  Col,
  Button,
  Breadcrumb,
  Space,
  Checkbox,
  message,
  Upload,
  Modal,
  Popconfirm,
  Tooltip,
  Input,
  Image
} from "antd";
import {
  SearchOutlined,
  UploadOutlined,
  TableOutlined,
  FolderFilled,
  UnorderedListOutlined,
  FolderAddOutlined,
  FileFilled,
  LoadingOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  InboxOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  HomeOutlined,
  FileZipOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import { KButton, CustomTable } from "../KComponents";
import KModal from "../common/elements/KModal/KModal";
import { Global, css } from "@emotion/react";
import "moment/locale/es-mx";
import moment from "moment";
import axiosApi, {API, urlfull} from "../../lib/utils/axiosApi";
import DragAndDrop from "../DragAndDrop";
import { async } from "@firebase/util";
import {connect} from 'react-redux';
import {downloadFile} from "../../lib/utils/utils";
import _ from "lodash";

const ServiceFileView = ({ idFolder=null, nameFolder=null, intl, settings }) => {
  const history = useHistory();
  const { Title, Text } = Typography;
  const [viewType, setViewType] = useState("list");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [modalFile, setModalFile] = useState(false);
  const [folderName, setFolderName] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [propsDragger, setPropsDragger] = useState(null)
  const [createFolder, setCreateFolder] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [objEdit, setObjEdit] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [imageList, setImageList] = useState([]);

  

  const { Dragger } = Upload;
  const { Meta } = Card;

  let { id } = useParams();
  const serviceId = id;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // Asegurarse de que selectedRowKeys es un arreglo
      if (Array.isArray(selectedRowKeys)) {
        setSelectedRowKeys(selectedRowKeys);
      } else {
        console.error('selectedRowKeys is not an array:', selectedRowKeys);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: '¿Estás seguro de eliminar los archivos seleccionados?',
      content: 'Esta acción no se puede deshacer.',
      onOk: handleDeleteSelected,
    });
  };

  const handleDeleteSelected = async () => {
    
    try {
      const res = await axiosApi.post(`${API.SERVICE.SERVICE_ORDER_FILE}/delete-multiple/ `,{ids: selectedRowKeys});
      
      if(res?.data?.message ){
        message.success(res?.data?.message);
        // Refrescar los datos
      }
       setSelectedRowKeys([]);
       getServicesOrderFile();
    } catch (error) {
      message.error('Error al eliminar archivos');
    }
  };


  const columns = [
    /* {
      title: "",
      key: "name",
      align: "center",
      width: 50,
      render: (row) => <Checkbox></Checkbox>,
    }, */
    {
      title: "",
      key: "type",
      align: "center",
      width: 100,
      render: (row) => <GetIconFile file={row} fileType={getType(row)} />,
    },
    {
      title: "Nombre",
      key: "name",
      render: (row) => row.is_folder?
          <a onClick={() => history.push(`/services-files/${serviceId}/${row.name}/${row.id}`)}>{row.name}</a>:
          <a href={row.file} target={'_blank'}>{row.name}</a>
    },
    {
      title: "Fecha de modificación",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "center",
      render: (timestamp) => <>{moment(timestamp).format("DD MMMM YYYY hh:mm A")}</>,
    },
    {
      title: "Acciones",
      key: "name",
      align: "center",
      render: (row) => (
        <Space size={20}>
          <EditOutlined onClick={() => showEditModal(row)} />
          <Popconfirm
            title={<FormattedMessage id="deleted.ask" />}
            onConfirm={() => onDelete(row.id)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const validateParent = (array) => {
    let treatArray = [];
    array.map((item) => {
      if (idFolder && item.parent) {
        treatArray.push(item);
      } else if (item.parent == null) {
        treatArray.push(item);
      }
    });

    return treatArray;
  };

  const getServicesOrderFile = async () => {
    setLoading(true);
    let params = "";
    if (idFolder) {
      params = `/?order=${serviceId}&parent=${idFolder}&page_size=100000`;
    } else {
      params = `/?order=${serviceId}&page_size=100000`;
    }
    try {
        let resp = await axiosApi.get(API.SERVICE.SERVICE_ORDER_FILE + `${params}`);
        console.log("resp", resp);
        if (resp.status === 200) {
          /* setPageObj(response.data); */
            setFiles(validateParent(resp.data.results));
        }
        
    } catch (error) {
        
    } finally {
      setLoading(false);
    }
  };

  const cancelModalFile = () => {
      setModalFile(false)
      setFileList([])
  }

  const cancelModalFolder = () =>{
      setFolderName(null);
      setCreateFolder(false);
  }

  const cancelModalUpd = () => {
      setFolderName(null);
      setFileName(null);
      setModalEditVisible(false);
      setCreateFolder(false);
  }

  const getType = (file) => {
    console.log("file =>", file);
    if(!file.is_folder){
        let fileType = file.content_type.split("/");
        console.log("fileType ->", fileType[fileType.length - 1]);
        return fileType[fileType.length - 1];
    }else{
        return 'folder';
    }
    
  };

  const showEditModal = (item) => {
    setObjEdit(item)
    setModalEditVisible(true);
    setFileName(item.name)
  }

  


  useEffect(() => {
    const fetchData = async () => {
      await getServicesOrderFile();
    };
    fetchData();
    setPropsDragger({
      name: 'file',
      multiple: true,
      showUploadList: true,
      onChange(info) {
        const { status } = info.file;
        const { fileList } = info;
        
        // Actualizar el estado de todos los archivos en la lista
        if (status === 'done') {
          message.success(`${info.file.name} se subió correctamente.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} falló al subirse.`);
        }
        
        // Si todos los archivos se han procesado, actualizar la lista
        const isFinished = fileList.every(file => 
          file.status === 'done' || file.status === 'error'
        );
        
        if (isFinished) {
          getServicesOrderFile();
        }
      },
    });
  }, [idFolder]);

  useEffect(() => {
    setImageList(prepareImageGallery(files));
  }, [files]);

  const onDelete = async (id) => {
    try {
      const res = await axiosApi.delete(
        `${API.SERVICE.SERVICE_ORDER_FILE}/${id}`
      );
      console.log("eeee", res);
      if (res.status === 204) {
        message.success(intl.formatMessage({ id: "item.deleteSuccess" }));
        await getServicesOrderFile();
      } else {
        message.error(intl.formatMessage({ id: "item.brand.registerErr" }));
      }
    } catch (e) {
      message.error(intl.formatMessage({ id: "item.brand.registerErr" }));
    }
  };
  
  const saveFolder = async () => {
    let req = {
      name: folderName,
      is_folder: true,
      is_autogenerated: false,
      content_type: null,
      size: 0,
      order: serviceId,
      parent: null,
    };
    try {
      let res = await axiosApi.post(API.SERVICE.SERVICE_ORDER_FILE + "/", req);
      console.log('res',res)
      message.success(intl.formatMessage({ id: "item.saveSuccess" }));
      getServicesOrderFile();
      setCreateFolder(false);
    } catch (e) {
      message.error(e);
    } 
  };


  const sendFiles = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    try {
      const formData = new FormData();
      formData.append("name", file.name);
      formData.append("is_folder", false);
      formData.append("is_autogenerated", false);
      formData.append("order", serviceId);
      formData.append("content_type", file.type);
      formData.append("size", file.size);
      formData.append("file", file);
      
      if (idFolder) {
        formData.append("parent", parseInt(idFolder));
      }

      const config = {
        onUploadProgress: event => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          onProgress({ percent });
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      const res = await axiosApi.post(API.SERVICE.SERVICE_ORDER_FILE + "/", formData, config);
      
      if (res.status === 200 || res.status === 201) {
        onSuccess(res.data);
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      onError({ 
        status: error.response?.status || 500,
        name: file.name,
        message: error.message || 'Error al subir el archivo'
      });
      message.error(`Error al subir ${file.name}`);
    }
  };

  const resetpropsFile = () => {
    setPropsDragger({
      name: 'file',
      multiple: true,
      showUploadList: true,
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} se subió correctamente.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} falló al subirse.`);
        }
      }
    });
  };


  const saveFile = async () => {
    let req = new FormData();
    req.append("name", fileList[0].name);
    req.append("is_folder", false);
    req.append("is_autogenerated", false);
    req.append("order", serviceId);
    req.append("content_type", fileList[0].type);
    req.append("size", fileList[0].size);
    req.append("file", fileList[0]);
    if (idFolder) {
      req.append("parent", parseInt(idFolder));
    }
    try {
      let res = await axiosApi.post(API.SERVICE.SERVICE_ORDER_FILE + "/", req);
        message.success(intl.formatMessage({ id: "item.saveSuccess" }));
        getServicesOrderFile();
        console.log(res);
        setModalFile(false);
    } catch (e) {
        message.error(e);
    } finally {
        setFileList([])
      /* formFile.resetFields(); */
    }
  };

  const updNameFile = async () =>{
      let req = {
        name: fileName,
        order: serviceId,
      };
      try {
        let res = await axiosApi.put(
          API.SERVICE.SERVICE_ORDER_FILE + "/" + objEdit.id + "/",
          req
        );
        console.log('res',res)
        message.success(intl.formatMessage({ id: "item.editSuccess" }));
        getServicesOrderFile();
        cancelModalUpd();
      } catch (e) {
        message.error(e);
      }
  }
  
  const goFolder = (nClicks, item) => {
      if (nClicks === 2){
          if (item.is_folder) {
            history.push(`/services-files/${serviceId}/${item.name}/${item.id}`);
          }else if(item.file){
                window.open(item.file, "_blank");
          }
      }
  };

  const downloadZipFile=async()=>{
    setDownloading(true)
    try{
      const res = await  axiosApi.get(`/service/order/${serviceId}/download-zip-files/${idFolder ? `?parent=${idFolder}`:''}`,{responseType: 'blob'})
      downloadFile(res.data,`carpeta`,'application/zip')
      message.success('En breve se descargará tus archivos')
    }catch (e){
      message.error('Hubo un problema en la descarga, por favor intenta nuevamente mas tarde.')
    }finally {
      setDownloading(false)
    }
  }


  const validateArray = (array) => {
    let foldersArray = [];
    let filesArray = [];
    array.map((item, index) => {
      if (item.is_folder) {
        foldersArray.push(item);
      } else {
        filesArray.push(item);
      }
    });

    return { foldersArray, filesArray };
  };

  const prepareImageGallery = (files) => {
    return files
      .filter(file => {
        if (!file.is_folder && file.content_type) {
          const type = file.content_type.split("/")[1];
          return ['png', 'jpg', 'jpeg'].includes(type);
        }
        return false;
      })
      .map(file => ({
        uid: file.id,
        url: file.file,
        title: file.name
      }));
  };

  /* Components */
  const GetIconFile = ({ fileType, file }) => {
    switch (fileType) {
      case "png":
      case "jpg":
      case "jpeg":
        return (
          <Image
            preview={false}
            width={100}
            src={file?.file}
            onClick={(e) => {
              e.stopPropagation();
              const currentIndex = files.findIndex(f => f.file === file.file);
              setPreviewImage(file?.file);
              setPreviewVisible(true);
            }}
            style={{ cursor: 'pointer' }}
          />
        );
      case "pdf":
        return <FilePdfOutlined />;
      case "zip":
        return <FileZipOutlined />;
      case "folder":
        return <FolderFilled />;
      default:
        return <FileOutlined />;
    }
  };
  
  const GridDisplayFiles = ({currentFiles}) => {
    return (
      <>
        {validateArray(currentFiles).foldersArray.length > 0 && (
          <Row gutter={[10, 10]} style={{ marginBottom: 50 }}>
            <Col span={24}>
              <p>
                <FormattedMessage id="services.files.folders" />
              </p>
            </Col>
            {validateArray(currentFiles).foldersArray.map((item) => (
              <Col xs={24} sm={12} md={8} xl={6} xxl={3}>
                <Card
                  onClick={(e) => goFolder(e.detail, item)}
                  hoverable
                  actions={
                    !item.is_autogenerated
                      ? [
                          <Popconfirm
                            title={<FormattedMessage id="deleted.ask" />}
                            onConfirm={() => onDelete(item.id)}
                          >
                            <Button type={"link"} danger>
                              <FormattedMessage id="layout.delete" />
                            </Button>
                          </Popconfirm>,
                          <Button
                            type={"link"}
                            onClick={() => showEditModal(item)}
                            shape="round"
                            icon={<EditOutlined key="edit" />}
                            size={"small"}
                          >
                            <FormattedMessage id="layout.edit" />
                          </Button>,
                        ]
                      : [
                          <a style={{ fontSize: "12px", color: "black" }}>
                            <FormattedMessage id="services.files.autogenerated" />
                          </a>,
                        ]
                  }
                  cover={
                    <div
                      /* onClick={() => goFolder(item)} */
                      style={{ textAlign: "center", padding: 10 }}
                    >
                      {item.is_folder ? (
                        <FolderFilled
                          style={{
                            fontSize: "60px",
                            color: "#2D2CF5",
                          }}
                        />
                      ) : (
                        <GetIconFile file={item} fileType={getType(item)} />
                      )}
                    </div>
                  }
                >
                  <Meta
                    /* onClick={() => goFolder(item)} */
                    title={
                      <div style={{ textAlign: "center", marginBottom: 10 }}>
                        <a style={{ color: "black" }}>{item.name}</a>
                      </div>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        )}
        {validateArray(currentFiles).filesArray.length > 0 &&
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <p>
              <FormattedMessage id="services.files.files" />
            </p>
          </Col>

          {validateArray(currentFiles).filesArray.map((item) => (
            <Col xs={24} sm={12} md={8} xl={6} xxl={3}>
              <Card
                onClick={(e) => goFolder(e.detail, item)}
                hoverable
                actions={
                  !item.is_autogenerated
                    ? [
                        <Popconfirm
                          title={<FormattedMessage id="deleted.ask" />}
                          onConfirm={() => onDelete(item.id)}
                        >
                          <Button type={"link"} danger>
                            <FormattedMessage id="layout.delete" />
                          </Button>
                        </Popconfirm>,
                        <Button
                          type={"link"}
                          onClick={() => showEditModal(item)}
                          shape="round"
                          icon={<EditOutlined key="edit" />}
                          size={"small"}
                        >
                          <FormattedMessage id="layout.edit" />
                        </Button>,
                      ]
                    : [
                        <a style={{ fontSize: "12px", color: "black" }}>
                          <FormattedMessage id="services.files.autogenerated" />
                        </a>,
                      ]
                }
                cover={
                  <div
                    /* onClick={() => goFolder(item)} */
                    style={{ textAlign: "center", padding: 10 }}
                  >
                    {item.is_folder ? (
                      <FolderFilled
                        style={{
                          fontSize: "60px",
                          color: "#2D2CF5",
                        }}
                      />
                    ) : (
                      <GetIconFile file={item} fileType={getType(item)} />
                    )}
                  </div>
                }
              >
                <Meta
                  /* onClick={() => goFolder(item)} */
                  title={
                    <div style={{ textAlign: "center", marginBottom: 10 }}>
                      <a style={{ color: "black" }}>{item.name}</a>
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
        }
      </>
    );
  }
  

  return (
    <>
      <Global
        styles={css`


            .modalfiles .ant-modal-body {
              height: 700px;
            }
            
          .spaceButtonsActions .ant-space-item {
            margin-top: auto;
          }
          .spaceButtonsActions .ant-space-item > span.anticon {
            margin-top: auto;
            font-size: 25px;
          }
          .breadcrumbFiles {
            font-size: 18px;
            font-weight: 500px;
          }
          .ant-breadcrumb {
            font-size: 18px;
            font-weight: 500px;
          }
        `}
      />
      <Layout style={{ marginTop: 10 }}>
        <Card className="card_details_service" loading={loading} style={{ paddingBottom: 20 }}>
          <Row justify="space-between">
            <Col>
              {nameFolder ? (
                <Breadcrumb>
                  <Breadcrumb.Item
                    style={{ cursor: "pointer" }}
                    onClick={() => history.goBack()}
                  >
                    Todos los archivos
                  </Breadcrumb.Item>
                  {nameFolder && (
                    <Breadcrumb.Item>{nameFolder}</Breadcrumb.Item>
                  )}
                </Breadcrumb>
              ) : (
                <Text className="breadcrumbFiles">Todos los archivos</Text>
              )}
              {/* {nameFolder ?  : <></>} */}
            </Col>
            <Col>
              <Space size={20} className="spaceButtonsActions">
                {
                  (files&&files.length>0) ? downloading ?  <LoadingOutlined/> :<Tooltip
                  title={"Descargar la carpeta completa"}
                > <CloudDownloadOutlined  onClick={()=> downloadZipFile() } style={{fontSize:26}} /> </Tooltip>:null
                }

                {
                  !idFolder &&
                    <Tooltip
                      title={<FormattedMessage id="services.files.newFolder" />}
                    >
                      <FolderAddOutlined onClick={() => setCreateFolder(true)} />
                    </Tooltip>
                }

                <Tooltip
                  title={"Vista tipo lista"}
                >

                <UnorderedListOutlined onClick={() => setViewType("list")} />
                </Tooltip>
                <Tooltip
                  title={"Vista tipo cuadrícula"}
                >
                <TableOutlined onClick={() => setViewType("grid")} />
                </Tooltip>
                <KButton
                  type={"primary"}
                  text={"Subir Archivo"}
                  icon={<UploadOutlined />}
                  onClick={() => setModalFile(true)}
                />
                <Button onClick={confirmDelete} 
                disabled={!selectedRowKeys.length}>Eliminar seleccionados</Button>
              </Space>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              {viewType === "list" ? (
                <CustomTable
                  columns={columns}
                   rowKey="id" 
                   rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  dataSource={files}
                  pagination={{ pageSize: 10}}
                  onRow={(record) => ({
                    onClick: (e) => goFolder(e.detail, record),
                  })}
                />
              ) : (
                <GridDisplayFiles currentFiles={files} />
              )}
            </Col>
          </Row>
        </Card>
      </Layout>



      <KModal
        className={"modalfiles"}
        title={'Subir archivos'}
        visible={modalFile}
        style={{minHeight:'400px'}}
        onCancel={()=>{
          getServicesOrderFile();
          resetpropsFile();
          setModalFile(false);
        }}
      >
        <Row justify="center" gutter={[10, 15]}>
          <Col span={24}>
            <Dragger 
              {...propsDragger} 
              customRequest={sendFiles}
              progress={{
                strokeColor: {
                  '0%': '#108ee9',
                  '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Arrastra aquí uno o más archivos para subirlos
              </p>
              <p className="ant-upload-hint">
                Puedes seleccionar múltiples archivos a la vez o hacer clic para seleccionarlos
              </p>
            </Dragger>

            <KButton
              type={"outline"}
              text={"Cerrar"}
              style={{ display: "block", marginTop: 30 }}
              onClick={() => {
                getServicesOrderFile();
                resetpropsFile();
                setModalFile(false);
              }}
            />
          </Col>
        </Row>
      </KModal>
      <KModal
        title={<FormattedMessage id="services.files.newFolder" />}
        visible={createFolder}
      >
        <Row justify="center">
          <Col span={24}>
            <Input
              className={"kinput"}
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </Col>
          <Col style={{ marginTop: 20 }}>
            <Space>
              <KButton
                type={"primary"}
                text={intl.formatMessage({ id: "cancel" })}
                onClick={() => cancelModalFolder()}
              />
              <KButton
                type={"secondary"}
                text={intl.formatMessage({ id: "save" })}
                onClick={() => saveFolder()}
              />
            </Space>
          </Col>
        </Row>
      </KModal>

      <KModal visible={modalEditVisible}>
        title={<FormattedMessage id="services.files.EditName" />}
        <Row justify="center">
          <Col span={24}>
            <Input
              className={"kinput"}
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </Col>
          <Col style={{ marginTop: 20 }}>
            <Space>
              <KButton
                type={"primary"}
                text={intl.formatMessage({ id: "cancel" })}
                onClick={() => cancelModalUpd()}
              />
              <KButton
                type={"secondary"}
                text={intl.formatMessage({ id: "save" })}
                onClick={() => updNameFile()}
              />
            </Space>
          </Col>
        </Row>
      </KModal>

      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: previewVisible,
            onVisibleChange: (vis) => setPreviewVisible(vis),
            current: files.findIndex(f => f.file === previewImage),
            countRender: (current, total) => `${current} de ${total}`,
          }}
        >
          {files
            .filter(file => {
              if (!file.is_folder && file.content_type) {
                const type = file.content_type.split("/")[1];
                return ['png', 'jpg', 'jpeg'].includes(type);
              }
              return false;
            })
            .map((file) => (
              <Image key={file.id} src={file.file} />
            ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

const mapState = (state) => ({
  settings: state.settings
});

export default injectIntl(connect(mapState, {})(ServiceFileView))